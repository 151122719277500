import column from './column';

/**
 * Column display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if column present
 */

const peopleGrid = (() => {
  const fadeIn = () => {
    const col = $('.people3col:not(.degree-faculty) .column__col');

    col.each(function(i, el) {
      setTimeout(function() {
        $(el).addClass('js-fade-in');
      }, i * 300);
    });
  };

  const init = () => {
    if (!$('.people3col').length) {
      return;
    }

    if ($(window).width() > 699) {
      fadeIn();
    }
  };

  return {
    init,
  };
})();

export default peopleGrid;
