import Instafeed from 'instafeed.js';

const instagramLoader = (() => {
  const feed = new Instafeed({
    get: 'user',
    userId: '1423273931',
    clientId: '3b7569ccd2224b58b1c2f1a1806fe871',
    accessToken: '1423273931.3b7569c.bc98eccae780465fbabee8fef09ba6b1',
    sortBy: 'most-recent',
    limit: 7,
    template:
      '<div class="instagram__tile column__col"><a class="instagram__link" href="{{link}}" target="_blank" style="background-image: url({{image}})">{{link}}</a></div>',
    resolution: 'standard_resolution',
    after() {
      $('.instagram__feed').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        draggable: false,
        swipe: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 700,
            settings: 'unslick',
          },
        ],
      });
    },
  });

  const init = () => {
    if (!$('#instafeed').length) {
      return;
    }
    feed.run();
  };

  return {
    init,
  };
})();

export default instagramLoader;
