/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.carousel__wrapper').slick({
      dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
    });
  }

  function arrowPos() {
    $('.carousel__wrapper .slick-arrow').prependTo(
      '.slick-active .carousel__item',
    );

    $('.carousel__wrapper .slick-arrow').on('click resize', e => {
      $('.carousel__wrapper .slick-arrow').prependTo(
        '.slick-active .carousel__item',
      );
    });
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();

    $(window).on('resize load', arrowPos);
  };

  return {
    init,
  };
})();

export default carousel;
