import '@babel/polyfill';
import 'slick-carousel';
import 'object-fit-images';

import header from './object/header';
import interiorNav from './object/interior-nav';
import interiorSecondNav from './object/interior-nav-secondary';
import backToTop from './object/back-to-top';
import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagramLoader from './object/instafeed';
import twitterLoader from './object/twitter';
import carousel from './object/carousel';
import calendar from './object/calendar';
import table from './object/table';
import peopleGrid from './object/people-grid';
import bgVid from './object/bg-vid';
import blogListFilters from './object/blog-filters';
import waypoint from './object/waypoints';
import calInfo from './object/calendar-info';
import stats from './object/stats';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  header.init();
  interiorNav.init();
  interiorSecondNav.init();
  accordion.init();
  inlineVideo.init();
  instagramLoader.init();
  twitterLoader.init();
  calendar.init();
  carousel.init();
  peopleGrid.init();
  objectFitImages();
  backToTop.init();
  bgVid.init();
  blogListFilters.init();
  waypoint.init();
  calInfo.init();
  table.init();
  stats.init();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
      blogListFilters.init();
    }, 100);
  });
});
