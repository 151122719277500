const fadeInStats = (() => {
  const fadeIn = () => {
    const item = $('.stat3up__item');

    const waypoint = new Waypoint({
      element: document.getElementById('stat3up'),
      handler: () => {
        item.addClass('js-fade-in');
      },
      offset: '90%',
    });
  };

  const init = () => {
    if (!$('.stat3up').length) {
      return;
    }

    fadeIn();
  };

  return {
    init,
  };
})();

export default fadeInStats;
