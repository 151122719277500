/**
 * header display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if header present
 */

let $prevScroll = 0;
let $currentScroll = 0;

const header = (() => {
  // Logic to show / hide the main navigation after scrolling
  function showHideMenu() {
    const $header = $('header');
    $currentScroll = $(window).scrollTop();

    // If scrolling down
    if ($currentScroll > $prevScroll && !$header.hasClass('js-prevent-slide')) {
      if (
        $currentScroll > $header.height() &&
        ($('.main-menu__button').attr('aria-expanded') === 'false' &&
          $('.i-am__button').attr('aria-expanded') === 'false')
      ) {
        $header.addClass('js-slideUp');
      }
    } else {
      // Scrolling up, show menu
      $header.removeClass('js-slideUp');
    }

    // Add `top` class if scrolling near or at top of page
    if ($currentScroll <= $header.height()) {
      $header.addClass('js-top');
    } else {
      $header.removeClass('js-top');
    }

    $prevScroll = $currentScroll;
  }

  // Toggle open / close states function
  function expandCollapse(bttn, dropdown, close) {
    if (close) {
      bttn.attr('aria-expanded', 'false');
      dropdown.attr('aria-hidden', 'true');
    } else {
      bttn.attr('aria-expanded', 'true');
      dropdown.attr('aria-hidden', 'false');
    }
  }

  // Expand / collapse main navigation.
  function headerToggle() {
    let $delay = 0;

    // If `I AM` section is open, close and delay opening of main navigation
    if ($('.i-am--desktop > .i-am__button').attr('aria-expanded') === 'true') {
      $('.i-am--desktop > .i-am__dropdown').slideToggle(300);
      expandCollapse(
        $('.i-am--desktop > .i-am__button'),
        $('.i-am--desktop > .i-am__dropdown'),
        true,
      );
      $delay = 300;
    }

    // If search section is open, close and delay opening of I am section
    if ($('.search__button').attr('aria-expanded') === 'true') {
      expandCollapse($('.search__button'), $('.search__wrapper'), true);
      $('.search').removeClass('js-open');

      if ($(window).width() <= 1024) {
        $('.search__wrapper').slideUp();
      }
      $delay = 300;
    }

    setTimeout(() => {
      const scrollPoint = $(window).scrollTop();
      if ($(this).attr('aria-expanded') === 'false') {
        expandCollapse($('.main-menu__button'), $('.main-nav'), false);
        $('html,body').addClass('js-fixed');
        $('.overlay').addClass('js-visible');
        $('body').css({ top: -scrollPoint });
      } else {
        expandCollapse($('.main-menu__button'), $('.main-nav'), true);
        $('html, body').removeClass('js-fixed');
        $('.overlay').removeClass('js-visible');
        window.scrollTo(0, scrollPoint);

        if ($('header').hasClass('js-stickyOpen')) {
          $('header')
            .removeClass('js-stickyOpen')
            .addClass('js-slideUp');
        }
      }
    }, $delay);
  }

  // Expand / collapse I Am section.
  function iamToggle() {
    let $delay = 0;

    // If main menu section is open, close and delay opening of I am section
    if ($('.main-menu__button').attr('aria-expanded') === 'true') {
      expandCollapse($('.main-menu__button'), $('.main-nav'), true);
      $delay = 300;
    }

    // If search section is open, close and delay opening of I am section
    if ($('.search__button').attr('aria-expanded') === 'true') {
      expandCollapse($('.search__button'), $('.search__wrapper'), true);
      $('.search').removeClass('js-open');

      if ($(window).width() <= 1024) {
        $('.search__wrapper').slideUp();
      }
      $delay = 300;
    }

    setTimeout(() => {
      const scrollPoint = $(window).scrollTop();
      if ($(this).attr('aria-expanded') === 'false') {
        expandCollapse(
          $('.i-am--desktop > .i-am__button'),
          $('.i-am--desktop > .i-am__dropdown'),
          false,
        );
        $('html,body').addClass('js-fixed');
        $('.overlay').addClass('js-visible');
        $('body').css({ top: -scrollPoint });
      } else {
        expandCollapse(
          $('.i-am--desktop > .i-am__button'),
          $('.i-am--desktop > .i-am__dropdown'),
          true,
        );
        $('html,body').removeClass('js-fixed');
        $('.overlay').removeClass('js-visible');
        window.scrollTo(0, scrollPoint);

        if ($('header').hasClass('js-stickyOpen')) {
          $('header')
            .removeClass('js-stickyOpen')
            .addClass('js-slideUp');
        }
      }

      $('.i-am__dropdown').slideToggle(300);
    }, $delay);
  }

  // Expand / collapse search section.
  function searchToggle() {
    let $delay = 0;

    const $interiorBttn = $('.interior-nav--mobile .interior-nav__button');
    const $interiorDropdown = $(
      '.interior-nav--mobile .interior-nav__dropdown',
    );
    if ($interiorBttn.attr('aria-expanded') === 'true') {
      $interiorBttn.attr('aria-expanded', 'false');
      $interiorDropdown.attr('aria-hidden', 'true');
      $interiorDropdown.slideUp();
      $delay = 300;
    }

    // If `I AM` section is open, close and delay opening of main navigation
    if ($('.i-am--desktop > .i-am__button').attr('aria-expanded') === 'true') {
      $('.i-am--desktop > .i-am__dropdown').slideToggle(300);
      expandCollapse(
        $('.i-am--desktop > .i-am__button'),
        $('.i-am--desktop > .i-am__dropdown'),
        true,
      );
      $delay = 300;
    }

    // If main menu section is open, close and delay opening of search section
    if ($('.main-menu__button').attr('aria-expanded') === 'true') {
      expandCollapse($('.main-menu__button'), $('.main-nav'), true);
      $delay = 300;
    }

    setTimeout(() => {
      const scrollPoint = $(window).scrollTop();
      if ($(this).attr('aria-expanded') === 'false') {
        expandCollapse($('.search__button'), $('.search__wrapper'), false);
        $('html,body').addClass('js-fixed');
        $('.overlay').addClass('js-visible');
        $('body').css({ top: -scrollPoint });
      } else {
        expandCollapse($('.search__button'), $('.search__wrapper'), true);
        $('html,body').removeClass('js-fixed');
        $('.overlay').removeClass('js-visible');
        window.scrollTo(0, scrollPoint);

        if ($('header').hasClass('js-stickyOpen')) {
          $('header')
            .removeClass('js-stickyOpen')
            .addClass('js-slideUp');
        }
      }

      $('.search').toggleClass('js-open');

      if ($(window).width() <= 1024) {
        $('.search__wrapper').slideToggle(300);
      }

      $('.search__textbox').focus();
    }, $delay);
  }

  // Expand / collapse I Am (mobile) section.
  function iamMobileToggle() {
    if ($(this).attr('aria-expanded') === 'false') {
      expandCollapse(
        $('.i-am--mobile > .i-am__button'),
        $('.i-am--mobile > .i-am__dropdown'),
        false,
      );
    } else {
      expandCollapse(
        $('.i-am--mobile > .i-am__button'),
        $('.i-am--mobile > .i-am__dropdown'),
        true,
      );
    }

    $('.i-am--mobile > .i-am__dropdown').slideToggle(300);
  }

  // Header tab loops
  function headerTabLoop() {
    // Main navigation tab loop
    $('.main-menu__button').on('keydown', e => {
      if (
        e.which === 9 &&
        e.shiftKey &&
        $('.main-menu__button').attr('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        $('.info-for__links li:last-of-type a').focus();
      }
    });

    $('.info-for__links li:last-of-type a').on('keydown', e => {
      if (
        e.which === 9 &&
        !e.shiftKey &&
        $('.main-menu__button').attr('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        $('.main-menu__button').focus();
      }
    });

    // I Am tab loop
    $('.i-am--desktop > .i-am__button').on('keydown', e => {
      if (
        e.which === 9 &&
        e.shiftKey &&
        $('.i-am--desktop > .i-am__button').attr('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        $('.i-am--desktop > .i-am__dropdown li:last-of-type a').focus();
      }
    });

    $('.i-am--desktop >  .i-am__dropdown li:last-of-type a').on(
      'keydown',
      e => {
        if (
          e.which === 9 &&
          !e.shiftKey &&
          $('.i-am--desktop > .i-am__button').attr('aria-expanded') === 'true'
        ) {
          e.preventDefault();
          $('.i-am--desktop > .i-am__button').focus();
        }
      },
    );

    // Search tab loop
    $('.search__button').on('keydown', e => {
      if (
        e.which === 9 &&
        e.shiftKey &&
        $('.search__button').attr('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        $('.search__submit > input').focus();
      }
    });

    $('.search__submit > input').on('keydown', e => {
      if (
        e.which === 9 &&
        !e.shiftKey &&
        $('.search__button').attr('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        $('.search__button').focus();
      }
    });
  }

  // Expand / collapse first level navigation items
  function subNavToggle() {
    if ($(this).attr('aria-expanded') === 'false') {
      $('.subnav__button').attr('aria-expanded', 'false');
      $('.subnav__dropdown').attr('aria-hidden', 'true');
      $('.main-nav__primary > li').addClass('js-hidden');
      $(this)
        .parent()
        .removeClass('js-hidden');
      $(this)
        .next()
        .addClass('js-expanded');
      $('.subnav__button').attr('aria-expanded', 'false');
      expandCollapse($(this), $(this).next(), false);
    } else {
      $('.main-nav__primary > li').removeClass('js-hidden');
      $('.subnav__dropdown').removeClass('js-expanded');
      expandCollapse($(this), $(this).next(), true);
    }
  }

  // Clone I Am section for mobile view inside main navigation
  function iamClone() {
    $('.i-am')
      .clone()
      .removeClass('i-am--desktop')
      .addClass('i-am--mobile')
      .prependTo('.main-nav');
  }

  // Open navigation via side nav
  function sideNavOpenMenu() {
    $('header').addClass('js-stickyOpen');
    $('header').removeClass('js-slideUp');

    setTimeout(() => {
      $('.main-menu__button').click();
    }, 300);
  }

  function sideNavOpenSearch() {
    $('header').addClass('js-stickyOpen');
    $('header').removeClass('js-slideUp');

    setTimeout(() => {
      $('.search__button').click();
    }, 300);
  }

  function overlayClose() {
    const iAm = $('.i-am__button');
    const search = $('.search__button');
    const menu = $('.main-menu__button');

    // If `I AM` section is open, close and delay opening of main navigation
    if (iAm.attr('aria-expanded', 'true')) {
      $('.i-am--desktop > .i-am__dropdown').slideUp(300);
      expandCollapse(iAm, $('.i-am--desktop > .i-am__dropdown'), true);
    }

    // If main menu section is open, close and delay opening of search section
    if (menu.attr('aria-expanded', 'true')) {
      expandCollapse($('.main-menu__button'), $('.main-nav'), true);
    }

    if (search.attr('aria-expanded', 'true')) {
      expandCollapse($('.search__button'), $('.search__wrapper'), true);
      $('.search').removeClass('js-open');

      if ($(window).width() <= 1024) {
        $('.search__wrapper').slideUp();
      }
    }

    $('html, body').removeClass('js-fixed');
    $('.overlay').removeClass('js-visible');
  }

  const init = () => {
    iamClone();
    headerTabLoop();
    $('.main-menu__button').on('click', headerToggle);
    $('.i-am--desktop > .i-am__button').on('click', iamToggle);
    $('.i-am--mobile > .i-am__button').on('click', iamMobileToggle);
    $('.subnav__button').on('click', subNavToggle);
    $('.search__button').on('click', searchToggle);
    $(window).on('load scroll', showHideMenu);
    $('.side-nav__main-bttn').on('click', sideNavOpenMenu);
    $('.side-nav__search-bttn').on('click', sideNavOpenSearch);
    $('.overlay').on('click', overlayClose);
  };

  return {
    init,
  };
})();

export default header;
