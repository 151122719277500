/**
 * backToTop display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if backToTop present
 */

const backToTop = (() => {
  function backToTopEvent() {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      300,
      () => {
        $('.header__logo').focus();
      },
    );
  }

  const init = () => {
    $('.back-to-top').on('click', backToTopEvent);
  };

  return {
    init,
  };
})();

export default backToTop;
