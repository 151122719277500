/**
 * Blog List display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Blog List Filters present
 */

const blogListFilters = (() => {
  function showFilters() {
    const blogFilters = $('.blog__categories, .blog__archive');

    $('.blog__filters__open').on('click', function() {
      $('.blog__filters__open').attr('aria-expanded', function(i, attr) {
        let s = attr === 'true' ? 'false' : 'true';
	      return s
      });

      blogFilters.slideToggle();
      stopPropagation();

    });
  }

  const init = () => {
    if (!$('.blog__filters').length) {
      return;
    }

    if ($(window).width() <= 1024) {
      showFilters();
    }
  };

  return {
    init,
  };
})();

export default blogListFilters;