/* global Waypoint */
import 'waypoints/lib/noframework.waypoints';

/**
 * Faculty detail info box positioning
 * @param {*} $
 * @returns {init} Determine and run if event-detail__header present
 */

const calInfo = (() => {
  function waypointsInit() {
    const stickyEl = $('.event-detail__sticky-container');

    // eslint-disable-next-line no-new
    new Waypoint({
      element: stickyEl[0],
      handler(direction) {
        if (direction === 'down') {
          stickyEl.addClass('js-sticky');
        }
      },
      offset: '73',
    });

    // eslint-disable-next-line no-new
    new Waypoint({
      element: $('.event-detail__header')[0],
      handler(direction) {
        if (direction === 'up') {
          stickyEl.removeClass('js-sticky');
        }
      },
      offset: '73',
    });

    // eslint-disable-next-line no-new
    new Waypoint({
      element: $('.event-detail__body')[0],
      handler(direction) {
        if (direction === 'down') {
          stickyEl.addClass('js-fixed');
        }

        if (direction === 'up') {
          stickyEl.removeClass('js-fixed');
        }
      },
      offset() {
        return -this.element.offsetHeight + stickyEl.height() + 18;
      },
    });
  }

  const init = () => {
    if (!$('.event-detail__header').length) {
      return;
    }

    waypointsInit();
  };

  return {
    init,
  };
})();

export default calInfo;
