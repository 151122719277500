import 'waypoints/lib/noframework.waypoints';

const waypoints = (() => {
  function waypointsInit() {
    const waypoint = new Waypoint({
      element: document.getElementById('waypoint'),
      handler: () => {
        document.getElementById('waypoint').classList.add('animate');
      },
      offset: '90%',
    });
  }

  const init = () => {
    if (!$('#waypoint').length) {
      return;
    }

    waypointsInit();
  };

  return {
    init,
  };
})();

export default waypoints;
