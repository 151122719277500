/**
 * Table display object interactions and accessibility
 * @param {*} $
 * @returns {init} Determine and run if table present
 */

const table = (() => {
  const scrollTable = () => {
    $('table').wrap('<div class="table"><div class="table--scroll">');

    $('<button class="table--scroll__btn left" aria-hidden="true" tabindex="-1">' +
     '<span class="visually-hidden">Scroll Right</span></button>\n' +
     '<button class="table--scroll__btn right" aria-hidden="true" tabindex="-1">' +
     '<span class="visually-hidden">Scroll Left</span></button>').insertBefore('.table--scroll > table');

    $('.table--scroll__btn.right').on('click', function() {
      const elWidth = $(this)
        .siblings('table')
        .width();

      $(this)
        .parent('.table--scroll')
        .animate(
          {
            scrollLeft: elWidth,
          },
          300,
          'swing',
        );
    });

    $('.table--scroll__btn.left').on('click', function() {
      $(this)
        .parent('.table--scroll')
        .animate(
          {
            scrollLeft: 0,
          },
          300,
          'swing',
        );
    });

    function addScrollClasses() {
      const thisEL = $('.table--scroll');
      let scrollLeft = $('.table--scroll').scrollLeft();
      let scrollWidth =
        $('.table--scroll')[0].scrollWidth - $('.table--scroll')[0].clientWidth;

      if (scrollLeft === 0 && scrollWidth > 0) {
        $(thisEL).addClass('js-scroll-left');
        $(thisEL).removeClass('js-scroll-right');
      } else if (scrollLeft === scrollWidth && scrollWidth > 0) {
        $(thisEL).addClass('js-scroll-right');
        $(thisEL).removeClass('js-scroll-left');
      } else {
        $(thisEL).removeClass('js-scroll-left');
        $(thisEL).removeClass('js-scroll-right');
      }

      $('.table--scroll').scroll(function() {
        scrollLeft = $(this).scrollLeft();
        scrollWidth = $(this)[0].scrollWidth - $(this)[0].clientWidth;

        if (scrollLeft === 0 && scrollWidth > 0) {
          $(this).addClass('js-scroll-left');
          $(this).removeClass('js-scroll-right');
        } else if (scrollLeft === scrollWidth) {
          $(this).addClass('js-scroll-right');
          $(this).removeClass('js-scroll-left');
        } else {
          $(this).removeClass('js-scroll-left, js-scroll-right');
        }
      });
    }

    addScrollClasses();
  };

  const init = () => {
    if (!$('table').length) {
      return;
    }

    scrollTable();
  };

  return {
    init,
  };
})();

export default table;
