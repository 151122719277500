/**
 * interiorNav Secondary display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if interiorNav present
 */

const interiorSecondNav = (() => {
  function interiorSecondNavMobile() {
    const $mobileSecondWrapper =
      '.interior-nav-secondary--mobile > .interior-second-nav__dropdown';
    const $desktopSecondNavItems = $('.interior-nav-secondary--desktop').find(
      '.interior-nav__trail',
    );

    $($desktopSecondNavItems)
      .clone()
      .appendTo($mobileSecondWrapper);

    $($mobileSecondWrapper)
      .find('.interior-nav-secondary__dropdown--inner')
      .last()
      .addClass('js-last')
      .find('.item--primary a')
      .append('<span></span>');
  }

  function interiorNavSecondMobileToggle() {
    if ($(this).attr('aria-expanded') === 'false') {
      $('header').addClass('js-interior-second-nav--open');
      $(this).attr('aria-expanded', 'true');
      $(this)
        .next()
        .attr('aria-hidden', 'false');
    } else {
      $('header').removeClass('js-interior-second-nav--open');
      $(this).attr('aria-expanded', 'false');
      $(this)
        .next()
        .attr('aria-hidden', 'true');
    }

    $(this)
      .next()
      .slideToggle(300);
  }

  function interiorSecondNavDropDown(e) {
    e.preventDefault();

    if (!$(this).hasClass('js-expanded')) {
      $('.interior-nav-secondary .item--has-children .menu__toggle')
        .attr('aria-expanded', 'false')
        .removeClass('js-expanded')
        .next()
        .slideUp(300);

      $(this)
        .attr('aria-expanded', 'true')
        .addClass('js-expanded')
        .next()
        .addClass('js-open')
        .slideDown('300');
    } else {
      $('.interior-nav-secondary .item--has-children .menu__toggle')
        .attr('aria-expanded', 'false')
        .removeClass('js-expanded')
        .next()
        .slideUp(300);
    }
  }

   function openCloseSecondNavSubMenus() {
    const childLinks = $('.interior-nav__dropdown--inner');
    const parentLink = $(
      '.interior-nav-secondary--desktop .item--has-children > a',
    );
    const lastItem = $(
      '.interior-nav-secondary--desktop .interior-nav__dropdown--inner li:last-child > a',
    );

    parentLink.on('keydown', function(e) {
      $(e.target)
        .parent()
        .find('.interior-nav__dropdown--inner')
        .addClass('js-visible');
    });

    childLinks.on('focusout mouseout', function(e) {
      $(e.target)
        .removeAttr('style')
        .parent()
        .find('.interior-nav__dropdown--inner')
        .removeClass('js-visible');
    });

    // Tabbing Backwards
    parentLink.on('keydown', function(e) {
      if (e.which === 9 && e.shiftKey === true) {
        $(e.target)
          .parent()
          .find('.interior-nav__dropdown--inner')
          .removeClass('js-visible');
      }
    });

    lastItem.on('keydown', function(e) {
      if (e.which === 9 && !e.shiftKey === true) {
        if (
          $(this)
            .closest('.interior-nav__dropdown--inner')
            .hasClass('js-visible')
        ) {
          $(this)
            .closest('.interior-nav__dropdown--inner')
            .removeClass('js-visible');
        }
      }
    });
  }

  const init = () => {
    interiorSecondNavMobile();
    openCloseSecondNavSubMenus();
    $('.interior-nav-secondary .item--has-children .menu__toggle').on(
      'click',
      interiorSecondNavDropDown,
    );
    $('.interior-second-nav__button').on(
      'click',
      interiorNavSecondMobileToggle,
    );
  };

  return {
    init,
  };
})();

export default interiorSecondNav;
