const bgVid = (() => {
  function bgVidToggle(e) {
    e.stopPropagation();

    if (e.type === 'click' || e.which === '13') {
      if (document.getElementById('home-hero-vid').paused) {
        document.getElementById('home-hero-vid').play();
        $('.homepage-hero__video')
          .addClass('playing')
          .removeClass('paused');
      } else {
        document.getElementById('home-hero-vid').pause();
        $('.homepage-hero__video')
          .addClass('paused')
          .removeClass('playing');
      }
    }
  }
  const init = () => {
    if (!$('.homepage-hero').length) {
      return;
    }
    $('.homepage-hero__video, .homepage-hero').on('click keydown', bgVidToggle);
  };

  return {
    init,
  };
})();

export default bgVid;
