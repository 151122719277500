/**
 * interiorNav display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if interiorNav present
 */

const interiorNav = (() => {
  function interiorNavInit() {
    if ($('.interior-nav').length) {
      $('header').addClass('js-interior-nav');
    }
  }

  function interiorNavMobileTabLoop() {
    const $bttn = $('.interior-nav__button');
    const $lastLink = $('.interior-nav__dropdown--inner.js-last .item')
      .last()
      .find('a');

    $bttn.on('keydown', e => {
      if (
        e.which === 9 &&
        e.shiftKey &&
        $bttn.attr('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        $lastLink.focus();
      }
    });

    $lastLink.on('keydown', e => {
      if (
        e.which === 9 &&
        !e.shiftKey &&
        $bttn.attr('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        $bttn.focus();
      }
    });
  }

  function interiorNavMobile() {
    const $mobileWrapper = '.interior-nav--mobile > .interior-nav__dropdown';
    const $desktopNavItems = $('.interior-nav--desktop').find(
      '.interior-nav__trail > .item',
    );

    $($desktopNavItems[0])
      .clone()
      .appendTo($mobileWrapper)
      .wrap('<ul></ul>');
    $($desktopNavItems[1])
      .clone()
      .appendTo(`${$mobileWrapper} .item--level-1`)
      .wrap('<ul></ul>');

    for (let i = 2; i < $desktopNavItems.length; i++) {
      $($desktopNavItems[i])
        .clone()
        .insertAfter(
          $(`${$mobileWrapper} .item--level-${i}`).find('.item--active-trail'),
        );
    }

    $($mobileWrapper)
      .find('.item--active-trail')
      .remove();

    $($mobileWrapper)
      .find('.interior-nav__dropdown--inner')
      .last()
      .addClass('js-last')
      .find('.item--primary a')
      .append('<span></span>');

    interiorNavMobileTabLoop();
  }

  function interiorNavMobileToggle() {
    if ($(this).attr('aria-expanded') === 'false') {
      $('header').addClass('js-interior-nav--open');
      $(this).attr('aria-expanded', 'true');
      $(this)
        .next()
        .attr('aria-hidden', 'false');
    } else {
      $('header').removeClass('js-interior-nav--open');
      $(this).attr('aria-expanded', 'false');
      $(this)
        .next()
        .attr('aria-hidden', 'true');
    }

    $(this)
      .next()
      .slideToggle(300);
  }

  function interiorNavFocusToggle(e) {
    $('.interior-nav__dropdown--inner')
      .not($(e.target).next())
      .removeAttr('style');
    $(e.target)
      .next()
      .attr('style', 'visibility: visible; opacity: 1;');
  }

  const init = () => {
    interiorNavInit();
    interiorNavMobile();
    $('.interior-nav__button').on('click', interiorNavMobileToggle);
    $('.interior-nav .item--primary.item--has-children > a').focus(
      interiorNavFocusToggle,
    );
  };

  return {
    init,
  };
})();

export default interiorNav;
